export default (context, inject) => {
  const meta = function(page, extras = null) {
    if(page) {
      let pageMeta = {
        title: page.fields.metaTitle ? page.fields.metaTitle.replace(' | Main Event', '') : page.fields.title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: page.fields.metaDescription
          },
          {
            hid: 'og:title',
            name: 'og:title',
            content: page.fields.metaTitle ? page.fields.metaTitle.replace(' | Main Event', '') : page.fields.title
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: page.fields.metaDescription
          }
        ],
        script: [],
        link: [
          {
            rel: 'canonical',
            href: `https://www.mainevent.com${context.route.path}${context.route.path.match(/\/$/) ? '' : '/'}`
          }
        ]
      }
      if(page.sys) {
        switch(page.sys.contentType.sys.id) {
          case 'center':
            if(page.fields.hours) {
              let hours = []
              Object.keys(page.fields.hours).map(d => {
                let similar = false
                let dayOfWeek = d.charAt(0).toUpperCase() + d.slice(1)

                hours.map(h => {
                  if(h.opens == page.fields.hours[d].openIntervals[0].start && h.closes == page.fields.hours[d].openIntervals[0].end) {
                    if(Array.isArray(h.dayOfWeek)) {
                      h.dayOfWeek.push(dayOfWeek)
                    } else {
                      let day = h.dayOfWeek
                      h.dayOfWeek = [day, dayOfWeek]
                    }
                    similar = true
                  }
                })
                
                if(!similar) {
                  hours.push({
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": dayOfWeek,
                    "opens": page.fields.hours[d].openIntervals[0].start,
                    "closes": page.fields.hours[d].openIntervals[0].end
                  })
                }
              })
              
              pageMeta.script.push({
                type: 'application/ld+json',
                json: {
                  "@context": "https://schema.org",
                  "@type": "LocalBusiness",
                  "name": page.fields.locationData.centerLongName,
                  "image": page.fields.storefrontImage.fields.file.url,
                  "@id": "https://www.mainevent.com/",
                  "url": `https://www.mainevent.com/locations/${page.fields.state}/${page.fields.slug}`,
                  "telephone": page.fields.locationData.telephone,
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": page.fields.locationData.address,
                      "addressLocality": page.fields.locationData.city,
                      "addressRegion": page.fields.locationData.state,
                      "postalCode": page.fields.locationData.zipcode,
                      "addressCountry": "US"
                  },
                  "openingHoursSpecification": hours
                }
              })
            }
            break
        }
      }
      return pageMeta
    } else {
      return {
        title: 'Not Found'
      }
    }
  }

  inject('metadata', meta)
  context.$metadata = meta
}