<template>
  <header id="site-header" class="z-40" :class="className">
    <div class="primary">
      <a href="https://www.mainevent.com">
        <img class="brand" src="//images.ctfassets.net/3dar4x4x74wk/cZZNUCdSENJnx4PZ6FLZs/7d534bbd01fc55c6392bc1618a192fae/logo.svg"/>
      </a>

      <nav class="hidden">
        <div class="link-wrapper relative">
          <a href="https://www.mainevent.com/location/all">Locations</a>
        </div>
      </nav>

      <div class="ml-auto flex items-center">
        <a class="toggle-menu flex cursor-pointer outline-none ml-auto" @click="toggleMobileMenu">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="21px" height="14px" viewBox="0 0 21 14" enable-background="new 0 0 21 14" xml:space="preserve">
          <g>
            <defs>
              <path id="SVGID_1_" d="M1,0h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.447,2,0,1.553,0,1S0.447,0,1,0z"/>
            </defs>
            <use xlink:href="#SVGID_1_"  overflow="visible" fill-rule="evenodd" clip-rule="evenodd"/>
            <clipPath id="SVGID_2_">
              <use xlink:href="#SVGID_1_"  overflow="visible"/>
            </clipPath>
            <g clip-path="url(#SVGID_2_)">
              <defs>
                <rect id="SVGID_3_" x="-284" y="-16" width="320" height="4548"/>
              </defs>
              <use xlink:href="#SVGID_3_"  overflow="visible"/>
              <clipPath id="SVGID_4_">
                <use xlink:href="#SVGID_3_"  overflow="visible"/>
              </clipPath>
              <rect x="-5" y="-5" clip-path="url(#SVGID_4_)" width="31" height="12"/>
            </g>
          </g>
          <g>
            <defs>
              <path id="SVGID_5_" d="M1,6h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.447,8,0,7.553,0,7S0.447,6,1,6z"/>
            </defs>
            <use xlink:href="#SVGID_5_"  overflow="visible" fill-rule="evenodd" clip-rule="evenodd"/>
            <clipPath id="SVGID_6_">
              <use xlink:href="#SVGID_5_"  overflow="visible"/>
            </clipPath>
            <g clip-path="url(#SVGID_6_)">
              <defs>
                <rect id="SVGID_7_" x="-284" y="-16" width="320" height="4548"/>
              </defs>
              <use xlink:href="#SVGID_7_"  overflow="visible"/>
              <clipPath id="SVGID_8_">
                <use xlink:href="#SVGID_7_"  overflow="visible"/>
              </clipPath>
              <rect x="-5" y="1" clip-path="url(#SVGID_8_)" width="31" height="12"/>
            </g>
          </g>
          <g>
            <defs>
              <path id="SVGID_9_" d="M1,12h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1c-0.553,0-1-0.447-1-1S0.447,12,1,12z"/>
            </defs>
            <use xlink:href="#SVGID_9_"  overflow="visible" fill-rule="evenodd" clip-rule="evenodd"/>
            <clipPath id="SVGID_10_">
              <use xlink:href="#SVGID_9_"  overflow="visible"/>
            </clipPath>
            <g clip-path="url(#SVGID_10_)">
              <defs>
                <rect id="SVGID_11_" x="-284" y="-16" width="320" height="4548"/>
              </defs>
              <use xlink:href="#SVGID_11_"  overflow="visible"/>
              <clipPath id="SVGID_12_">
                <use xlink:href="#SVGID_11_"  overflow="visible"/>
              </clipPath>
              <rect x="-5" y="7" clip-path="url(#SVGID_12_)" width="31" height="12"/>
            </g>
          </g>
          </svg>

          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="15.436px" height="15.436px" viewBox="0 0 15.436 15.436" enable-background="new 0 0 15.436 15.436" xml:space="preserve">
          <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M1.708,0.293l13.435,13.436c0.39,0.391,0.39,1.023,0,1.414
            c-0.391,0.391-1.024,0.391-1.415,0L0.293,1.707c-0.39-0.391-0.39-1.023,0-1.414C0.684-0.098,1.317-0.098,1.708,0.293z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M0.293,13.729L13.728,0.293c0.391-0.391,1.024-0.391,1.415,0
            c0.39,0.391,0.39,1.023,0,1.414L1.708,15.143c-0.391,0.391-1.024,0.391-1.415,0C-0.097,14.752-0.097,14.119,0.293,13.729z"/>
          </svg>
        </a>
      </div>
    </div>

    <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
      <nav v-show="showMobileMenu" class="mobile absolute lg:hidden">
        <div>
          <div class="mobile-menu-link-wrapper">
            <a href="https://www.mainevent.com/location/all">Locations</a>
          </div>
        </div>
      </nav>
    </transition>

    <location-dropdown class="mobile-location-dropdown" :show="showLocationDropdown"/>

  </header>
</template>

<script>
import LocationDropdown from '~/components/locations/LocationDropdown.vue'

const states = require('~/assets/config/states.json')

export default {
  components: {
    LocationDropdown
  },
  data: function() {
    return {
      info: {},
      showMobileMenu: false,
      showLocationDropdown: false,
      className: ''
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.hideMobileMenu)

    if (this.$route.name == 'index') {
      // this.className = 'index'
      // setTimeout(() => {
      //   this.className = 'index transition'
      // }, 100)
    }

    if (window.location.pathname == '/menu/bar' || window.location.pathname == '/menu/events') {
      document.querySelectorAll('[href="/menu/food"]')[0].classList.add('nuxt-link-active')
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.hideMobileMenu);
  },
  watch: {
    '$route.path': function(e) {
      this.current = e
      const header = document.querySelector('header')
      if (window.location.pathname == '/') {
        header.classList.add('index')
      } else {
        header.classList.remove('index')
        header.classList.add('transition')
      }
      if (window.location.pathname == '/menu/bar' || window.location.pathname == '/menu/events') {
        document.querySelectorAll('[href="/menu/food"]')[0].classList.add('nuxt-link-active')
      } else {
        document.querySelectorAll('[href="/menu/food"]')[0].classList.remove('nuxt-link-active')
      }
    }
  },
  computed: {
    location() {
      return this.$store.state.location
    },
    locationLink() {
      if(this.location) {
        return `/locations/${states[this.location.state].toLowerCase()}/${this.location.centerName.toLowerCase()}`
      } else {
        return null
      }
    },
    loggedIn() {
      return this.$store.state.loggedIn
    },
    links() {
      return this.$store.state.info.menus.main
    },
  },
  methods: {
    showSublinks(e) {
      let sublinks = document.querySelectorAll('.mobile .sublinks')
      let i
      for (i = 0; i < sublinks.length; i++) {
        sublinks[i].style.display = 'none'
      }
      e.target.nextElementSibling.style.display = 'block'
    },
    toggleLocationDropdown() {
      this.showLocationDropdown = !this.showLocationDropdown
    },
    toggleCart() {
      this.$emit('toggleCart', 'cart')
    },
    toggleMobileMenu() {
      let sublinks = document.querySelectorAll('.mobile .sublinks')
      let i
      for (i = 0; i < sublinks.length; i++) {
        sublinks[i].style.display = 'none'
      }
      let header = document.querySelector('header')
      this.showMobileMenu = !this.showMobileMenu
      if (this.showMobileMenu == true) {
        header.classList.add('mobile-menu-open')
      } else {
        header.classList.remove('mobile-menu-open')
        window.scrollTo(window.pageXOffset, window.pageYOffset - 1)
        window.scrollTo(window.pageXOffset, window.pageYOffset + 1)
      }
    },
    hideMobileMenu() {
      this.showMobileMenu = false
      this.showLocationDropdown = false
      let header = document.querySelector('header')
      header.classList.remove('mobile-menu-open')
    },
    handleScroll() {
      let header = document.querySelector('header')
      // let headerHeight = header.clientHeight
      let headerHeight = 65
      let scrollY = window.scrollY

      if (scrollY > headerHeight) {
        header.classList.add('opaque')
      } else {
        header.classList.remove('opaque')
      }
    },
  }
}
</script>

<style lang="scss">
#site-header.mobile-menu-open {
  background: rgba(31, 31, 30,1)!important;

  .toggle-menu {
    svg:first-of-type {
      display: none;
    }
    svg:last-of-type {
      display: block;
    }
  }

  .location {
    margin-top: 10px;
    margin-bottom: 15px;

    .location-icon img {
      height: 20px;
      min-width: 0;
    }

    .location-title {
      padding-left: 8px;
    }

    .location-dropdown-icon {
      cursor: pointer;
    }
  }
}

// header.index {
//   background: none;
//   transition: all 0s;
// }

#site-header.transition {
  transition: all .3s;
}

.link-wrapper .nuxt-link-active:after {
  content: '';
  border-bottom: 2px solid #fff;
  position: absolute;
  bottom: 7px;
  left: 12px;
  right: 12px;

  @media (max-width: 1130px) {
    left: 10px;
    right: 10px;
  }
}

.sublinks .nuxt-link-active:after {
  display: none;
}
</style>