import locations from '../assets/config/locations.json'

export default (context, inject) => {
  const api = {
    path() {
      return typeof window == 'undefined' ? '' : window.location.origin
    },
    url() {
      return process.env.API_URL || `${window.location.origin}/api`
    },
    getLocations: async function(query) {
      if(query.zipcode) {
        let testString = query.zipcode.toLowerCase()
        locations.map(l => {
          if(testString == l.term) {
            query = {
              lat: l.lat,
              lng: l.lng
            }
          }
        })
      }
      // query.verify = await context.$recaptcha.execute('login')
      if(process.env.API_DEV) {
        query.api = process.env.API_DEV
      }
      return await context.$axios.get(`${this.url()}/locations`, {
        params: query,
        headers: {
          verify: await context.$recaptcha.execute('login')
        }
      })
      .then(res => {
        return res.data.results
      }).catch(res => {
        return []
      })
    },
    getLocation: async function(slug) {
      let found = true
      let loc = context.store.state.routes.center[slug]
      if(!loc && process.server) {
        found = false
        loc = await context.$storage.getFile(`center/${slug}`)
      }
      if(!loc && (process.server || process.env.NODE_ENV == 'development')) {
        found = false
        loc = await context.$contentful.getEntry('center', slug)
        if(loc) {
          loc.activities = await this.getArticles('activity')
          // loc.events = await this.getArticles('event')
          loc.testimonials = await this.getArticles('testimonial')
        }
      } else if(!loc) {
        found = false
        loc = await context.$axios.get(`${this.path()}/_data/center/${slug}.json`)
        .then(res => {
          return res.data
        })
      }
      if(loc && !found) {
        if(process.server && process.env.NODE_ENV != 'development') {
          context.$storage.saveFile(`center/${slug}`, loc)
        }
        context.store.commit('setRoute', {
          type: 'center',
          name: slug,
          data: loc
        })
      }
      return JSON.parse(JSON.stringify(loc))
    },
    getPage: async function(slug, type = 'page', save = true) {
      let found = true
      let page = context.store.state.routes[type][slug]
      if(!page && process.server) {
        found = false
        page = await context.$storage.getFile(`${type}/${slug}`)
      }
      if(!page && (process.server || process.env.NODE_ENV == 'development')) {
        found = false
        page = await context.$contentful.getEntry(type, slug)
      } else if(!page) { // on production client
        found = false
        page = await context.$axios.get(`${this.path()}/_data/${type}/${slug}.json`)
        .then(res => {
          return res.data
        })
      }
      if(page && !found) {
        if(process.server && process.env.NODE_ENV != 'development') {
          context.$storage.saveFile(`${type}/${slug}`, page)
        }
        context.store.commit('setRoute', {
          type: type,
          name: slug,
          data: page
        })
      }
      return JSON.parse(JSON.stringify(page))
    },
    getArticles: async function(type = 'article', expand = true, store = true) {
      let found = true
      let articles = context.store.state.articles[type]
      if(!articles && process.server) {
        // this should be stored at the start of build/dev
        articles = await context.$storage.getFile(type)
      }
      if(!articles && process.env.NODE_ENV == 'development') {
        found = false
        articles = {
          entries: await context.$contentful.getEntries(type)
        }
      } else if(!articles) {
        found = false
        articles = await context.$axios.get(`${this.path()}/_data/${type}.json`)
        .then(res => {
          return res.data
        })
      }
      if(articles && !found) {
        context.store.commit('setArticles', {
          type: type,
          data: articles
        })
      }
      return JSON.parse(JSON.stringify(articles))
    }
  }

  inject('api', api)
  context.$api = api
}
