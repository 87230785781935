// formulate.config.js
import {
  en
} from '@braid/vue-formulate-i18n'

export default {
  plugins: [en],
  locale: 'en',
  useInputDecorators: false,
  classes: {
    outer: 'mb-4',
    input(context) {
      switch (context.classification) {
        case 'box':
          return 'rounded-full '
        case 'checkbox':
          return 'rounded-full '
        case 'button':
          return 'px-4 py-8 rounded-2xl text-lg text-white formulate-button w-full hover:bg-pepper hover:border-pepper transition-all duration-200 ease'
        case 'submit':
          return 'px-4 py-8 rounded-2xl text-lg text-white formulate-button w-full hover:bg-pepper hover:border-pepper transition-all duration-200 ease'
        default:
          return 'border border-gray-100 rounded-lg px-6 py-6 leading-none focus:border-pepper outline-none border-box w-full mb-1'
      }
    },
    label: 'font-body font-bold text-xl uppercase mb-1 block',
    help: 'text-xs mb-1 text-pepper',
    error: 'text-red-700 mb-1'
  },
  rules: {
    foobar: ({
      value
    }) => ['foo', 'bar'].includes(value)
  }
}
