(function () {

  
  function objectFit() {
    if ('objectFit' in document.documentElement.style === false) {
      // document.addEventListener('DOMContentLoaded', () => {
        Array.prototype.forEach.call(document.querySelectorAll('img[data-object-fit]'), image => {
          (image.runtimeStyle || image.style).background = `url("${image.getAttribute('data-src')}") no-repeat 50%/${image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit')}`
          image.src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${image.width}' height='${image.height}'%3E%3C/svg%3E`
        })
      // })
    }
  }

  function isIE(userAgent) {
    userAgent = userAgent || navigator.userAgent;
    return userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1 || userAgent.indexOf("Edge/") > -1;
  }

  if (isIE()) {
    setInterval(objectFit, 1000);
  }

  if ( typeof window.CustomEvent === "function" ) return false; //If not IE

  function CustomEvent( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})()

