export default (context, inject) => {
  const mulesoft = {
    async request(obj) {
      let origin = `${process.env.API_URL || `${window.location.origin}/api`}/mulesoft/`
      obj.url = origin + obj.url
      if(!obj.params) {
        obj.params = {}
      }
      if(process.env.API_DEV || process.env.MULESOFT_CLIENT_ID || context.query.clientId) {
        if(process.env.API_DEV) {
          if(!obj.params.api) {
            obj.params.api = process.env.API_DEV
          } else {
            obj.params.api = `${process.env.API_DEV}${obj.params.api}`
          }
        }
        if(context.store.state.clientId) {
          obj.params.client_id = context.store.state.clientId
        } else if(context.query.clientId) {
          obj.params.client_id = context.query.clientId
        // } else if(process.env.MULESOFT_CLIENT_ID) {
        //   obj.params.client_id = process.env.MULESOFT_CLIENT_ID
        }
      }
      if (obj.verify) {
        if(!obj.headers) {
          obj.headers = {}
        }
        obj.headers.verify = await context.$recaptcha.execute("login")
        delete obj.verify
      }
      return context.$axios(obj)
    },
    async requestFunCard(obj) {
      let origin = `${process.env.API_URL || `${window.location.origin}/api`}/funcards/`
      obj.url = origin + obj.url
      if(!obj.params) {
        obj.params = {}
      }
      if(process.env.API_DEV || process.env.MULESOFT_CLIENT_ID || context.query.clientId) {
        if(process.env.API_DEV) {
          obj.params.api = process.env.API_DEV
        }
        if(context.store.state.clientId) {
          obj.params.client_id = context.store.state.clientId
        } else if(context.query.clientId) {
          obj.params.client_id = context.query.clientId
        } else if(process.env.MULESOFT_CLIENT_ID) {
          obj.params.client_id = process.env.MULESOFT_CLIENT_ID
        }
      }
      if(!obj.headers) {
        obj.headers = {
          verify: await context.$recaptcha.execute('login')
        }
      }
      return context.$axios(obj)
    },
    async getAuth(obj = {}) {
      let accessToken = null
      if(context.store.state.accessToken) {
        accessToken = context.store.state.accessToken
      } else if(context.query.accessToken) {
        accessToken = context.query.accessToken
      } else {
        accessToken = await context.$okta.getAccessToken()
      }
      obj.authorization = `Bearer ${accessToken}`
      return obj
    },
    // Signup
    async submitContact(obj) {
      return this.request({
        method: 'post',
        url: 'contacts',
        verify: true,
        data: obj
      })
    },
    // Lead gen
    async leadGen(data) {
      return this.request({
        method: 'post',
        url: 'eventLeads',
        verify: true,
        data: data
      })
    },
    // Account access
    async getAccount() {
      return this.request({
        method: 'get',
        url: `accounts`,
        headers: await this.getAuth()
      })
    },
    async updateAccount(id, obj) {
      return this.request({
        method: 'patch',
        url: `accounts/${id}`,
        data: obj,
        headers: await this.getAuth()
      })
    },
    // Customer Orders
    async createCustomerCart(id, centerId, obj) {
      return this.request({
        method: 'post',
        url: `accounts/${id}/orders/${centerId}/carts`,
        headers: await this.getAuth(),
        data: obj
      })
    },
    async addCustomerCoupon(id, centerId, cartId, obj) {
      return this.request({
        method: 'post',
        url: `accounts/${id}/orders/${centerId}/carts/${cartId}/promotions`,
        headers: await this.getAuth(),
        data: obj
      })
    },
    async getCustomerRedirectUrls(id, centerId, cartId) {
      return this.request({
        method: 'post',
        url: `accounts/${id}/orders/${centerId}/carts/${cartId}/redirect-urls`,
        headers: await this.getAuth()
      })
    },
    async deleteCustomerCart(id, centerId, cartId) {
      return this.request({
        method: 'delete',
        url: `accounts/${id}/orders/${centerId}/carts/${cartId}`,
        headers: await this.getAuth()
      })
    },
    // Orders
    createCart(centerId, obj) {
      return this.request({
        method: 'post',
        url: `orders/${centerId}/carts`,
        data: obj
      })
    },
    getCart(centerId, cartId) {
      return this.request({
        method: 'get',
        url: `orders/${centerId}/carts/${cartId}`
      })
    },
    updateCart(centerId, cartId, obj) {
      return this.request({
        method: 'put',
        url: `orders/${centerId}/carts/${cartId}`,
        data: obj
      })
    },
    addCoupon(centerId, cartId, obj) {
      return this.request({
        method: 'post',
        url: `orders/${centerId}/carts/${cartId}/promotions`,
        data: obj
      })
    },
    getRedirectUrls(centerId, cartId) {
      return this.request({
        method: 'post',
        url: `orders/${centerId}/carts/${cartId}/redirect-urls`
      })
    },
    deleteCart(centerId, cartId) {
      return this.request({
        method: 'delete',
        url: `orders/${centerId}/carts/${cartId}`
      })
    },
    // Reservations
    async createReservation(obj) {
      return this.request({
        method: 'post',
        url: 'reservations',
        data: obj
      })
    },
    getReservation(id) {
      return this.request({
        method: 'get',
        url: `reservations/${id}`
      })
    },
    getReservationAvailability(obj) {
      return this.request({
        method: 'get',
        url: 'reservations/availabilities',
        params: obj
      })
    },
    getReservationAddOns(obj) {
      return this.request({
        method: 'get',
        url: 'reservations/add-ons',
        params: obj
      })
    },
    getReservationOptions(obj) {
      return this.request({
        method: 'get',
        url: 'reservations/options',
        params: obj
      })
    },
    // Packages
    getEventPackages(centerId) {
      return this.request({
        method: 'get',
        url: 'events/packages',
        verify: true,
        params: {
          centerId: centerId
        }
      })
    },
    getEventPackage(packageCode, desiredDate, desiredTime) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}`,
        params: {
          desiredStartDate: desiredDate,
          desiredStartTime: desiredTime
        }
      })
    },
    getEventPackageAvailability(packageCode, obj) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/availabilities`,
        params: obj
      })
    },
    getEventPackageMenu(packageCode, desiredDate, desiredTime) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/menu`,
        params: {
          desiredStartDate: desiredDate,
          desiredStartTime: desiredTime
        }
      })
    },
    getEventPackageActivities(packageCode) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/activities`
      })
    },
    getEventPackageExtras(packageCode, desiredDate, desiredTime) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/extras`,
        params: {
          desiredStartDate: desiredDate,
          desiredStartTime: desiredTime
        }
      })
    },
    getEventPackagePartyCity(packageCode, desiredDate, desiredTime) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/partyCity`,
        params: {
          desiredStartDate: desiredDate,
          desiredStartTime: desiredTime
        }
      })
    },
    createEventPackageQuote(obj) {
      return this.request({
        method: 'post',
        url: 'events/quotes',
        data: obj
      })
    },
    createEventPackageCart(obj) {
      return this.request({
        method: 'post',
        url: 'events/packages/bookings',
        data: obj
      })
    },
    // Checkout
    createCheckout(centerId, cartId, obj) {
      return this.request({
        method: 'post',
        url: `orders/${centerId}/carts/${cartId}/checkout`,
        verify: true,
        data: obj
      })
    },
    submitPayment(obj) {
      return this.request({
        method: 'post',
        url: 'payments',
        verify: true,
        data: obj
      })
    },
    // Fun Cards
    funCardBalance(funcardNumber, pin) {
      return this.requestFunCard({
        method: 'get',
        url: `funCards/${funcardNumber}/balance`,
        params: {
          pin: pin
        }
      })
    },
    // Center
    getCenter(id) {
      return this.request({
        method: 'get',
        url: `centers/${id}`,
        params: {
          includeVenues: true,
          includeEvents: true
        }
      })
    },
    getCenterProducts(id) {
      return this.request({
        method: 'get',
        url: `centers/${id}/products`
      })
    },
    // Leagues
    getLeagueCapacity(centerId) {
      return this.request({
        method: 'get',
        url: 'leagues/availability/leagueCapacity',
        params: {
          centerId
        }
      })
    },
    getTeamName(teamName) {
      return this.request({
        method: 'get',
        url: `leagues/availability/teamName`,
        params: {
          teamName
        }
      })
    },
    getTeamCapacity(teamId) {
      return this.request({
        method: 'get',
        url: `leagues/availability/teamCapacity`,
        params: {
          teamId
        }
      })
    },
    createTeam(teamInfo) {
      return this.request({
        method: 'post',
        url: `leagues/registration/team`,
        data: teamInfo
      })
    },
    addBowler(bowlerInfo) {
      console.log(bowlerInfo)
      return this.request({
        method: 'post',
        url: `leagues/registration/team/${bowlerInfo.teamId}`,
        data: bowlerInfo
      })
    },
    createBowler(bowlerInfo) {
      return this.request({
        method: 'post',
        url: `leagues/registration`,
        data: bowlerInfo
      })
    }
  }

  inject('mulesoft', mulesoft)
  context.$mulesoft = mulesoft
}