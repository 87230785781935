import moment from 'moment'

export const state = () => ({
  type: null,
  updated: null,
  location: null,
  guests: null,
  details: null,
  customer: null,
  quantity: 0,
  date: null,
  time: null,
  league: null,
  season: null,
  addons: null,
  package: null,
  experiences: {},
  meal: null,
  extras: {},
  summary: null,
  overview: null,
  balloons: null,
  tableware: [],
  pcFee: null,
  laneType: null,
  shoes: null,
  bookingEmail: null,
  bookingSMS: null,
  id: null,
  activityArray: null,
  viewCart: false,
})

export const mutations = {
  type(state, type) {
    if(type == null) {
      // localStorage.setItem('me_prevCart', JSON.stringify(state))
    } else {
      localStorage.removeItem('me_prevCart')
    }
    mutations.resetCart(state)
    state.type = type
    state.updated = moment()
    mutations.saveCart(state)
  },

  location(state, loc) {
    state.location = loc
    mutations.saveCart(state)
  },

  guests(state, guests) {
    state.guests = guests
    mutations.saveCart(state)
  },

  details(state, deets) {
    state.details = deets
    mutations.saveCart(state)
  },

  customer(state, customer) {
    state.customer = customer
    mutations.saveCart(state)
  },

  quantity(state, quantity) {
    state.quantity = quantity
    mutations.saveCart(state)
  },

  date(state, date) {
    state.date = date
    mutations.saveCart(state)
  },

  time(state, time) {
    state.time = time
    mutations.saveCart(state)
  },

  league(state, league) {
    state.league = league
    mutations.saveCart(state)
  },

  season(state, season) {
    state.season = season
    mutations.saveCart(state)
  },

  addons(state, addOns) {
    state.addons = addOns
    mutations.saveCart(state)
  },

  package(state, addOns) {
    state.package = addOns
    mutations.saveCart(state)
  },

  experiences(state, experiences) {
    state.experiences = experiences
    mutations.saveCart(state)
  },

  meal(state, meal) {
    state.meal = meal
    mutations.saveCart(state)
  },

  extras(state, extras) {
    state.extras = extras
    mutations.saveCart(state)
  },

  summary(state, obj) {
    state.summary = obj
    mutations.saveCart(state)
  },

  overview(state, obj) {
    state.overview = obj
    mutations.saveCart(state)
  },

  balloons(state, obj) {
    state.balloons = obj
    mutations.saveCart(state)
  },

  tableware(state, obj) {
    state.tableware = obj
    mutations.saveCart(state)
  },

  pcFee(state, pcFee) {
    state.pcFee = pcFee
    mutations.saveCart(state)
  },

  laneType(state, laneType) {
    state.laneType = laneType
    mutations.saveCart(state)
  },

  shoes(state, shoes) {
    state.shoes = shoes
    mutations.saveCart(state)
  },
  id(state, string){
    state.id = string;
    mutations.saveCart(state)
  },
  activityArray(state,activityArray){
    state.activityArray= activityArray;
    mutations.saveCart(state)
  },

  viewCart(state,viewCart){
    state.viewCart= viewCart;
    // mutations.saveCart(state)
  },

  bookingEmail(state, obj) {
    state.bookingEmail = obj
    mutations.saveCart(state)
  },

  bookingSMS(state, obj) {
    state.bookingSMS = obj
    mutations.saveCart(state)
  },

  cart(state, cart) {
    Object.keys(cart).map(k => {
      state[k] = cart[k]
    })
  },

  saveCart(cart) {
    // cart.updated = moment()
    localStorage.setItem('me_cart', JSON.stringify(cart))
  },

  resetCart(state) {
    Object.keys(state).map(k => {
      state[k] = null
    })

    localStorage.removeItem('me_cart')
  }
}
