<template>
<div class="flex w-16 h-8 mx-auto justify-between items-center">
  <div v-for="d in dots" :key="d.index" :class="`dot ${bgColor} rounded-full`" />
</div>
</template>

<script>
export default {
  props: [
    'color'
  ],
  data() {
    return {
      dots: [1, 2, 3]
    }
  },
  computed: {
    bgColor() {
      switch(this.color) {
        case 'purple':
          return 'bg-purple'
          break
        default:
          return 'bg-white'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$size: 16px;

.dot {
  width: $size;
  height: $size;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>