import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

if(process.env.BUGTRACKER) {
  Bugsnag.start({
    apiKey: process.env.BUGTRACKER,
    plugins: [new BugsnagPluginVue()]
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  Vue.use(bugsnagVue)
}