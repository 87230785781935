<template>
  <div class="error-404 bg-cover bg-center pb-20">
    <h1 class="w-full max-w-2xl mx-auto px-2 mb-12 mt-2">
      <error-header class="w-full" />
      <span>404</span>
    </h1>
    <p class="w-full max-w-lg mx-auto font-black uppercase text-2xl leading-none text-white text-center px-2 mb-8">Looks like you took a wrong turn. Let’s get you back to the fun.</p>
    <div class="flex justify-center">
      <div v-for="l in links" :key="l.index" class="px-3">
        <nuxt-link :to="l.to" class="button pulse text-center" v-html="l.title" />
      </div>
    </div>
  </div>
</template>

<script>
import ErrorHeader from './Header'

export default {
  components: {
    ErrorHeader
  },
  data() {
    return {
      links: [
        {
          to: '/book',
          title: 'Book a Party'
        },
        {
          to: '/locations',
          title: 'Find a Location'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.bg-cover {
  background-color: $pink;
  background-image: url('../../assets/img/404.jpg');

  h1 {
    span {
      float: left;
      font-size: 0;
    }
  } 
}
</style>