(function() {
  var s = document.createElement('script'),
    e = ! document.body ? document.querySelector('head') : document.body;
  s.src = '//acsbapp.com/apps/app/assets/js/acsb.js';
  s.async = s.defer = true;
  s.onload = function() {
    acsbJS.init({
      statementLink : '',
      feedbackLink : '',
      footerHtml : '',
      hideMobile : false,
      hideTrigger : false,
      language : 'en',
      position : 'left',
      leadColor : '#8A0FFE',
      triggerColor : '#8A0FFE',
      triggerRadius : '50%',
      triggerPositionX : 'left',
      triggerPositionY : 'bottom',
      triggerIcon : 'default',
      triggerSize : 'medium',
      triggerOffsetX : 20,
      triggerOffsetY : 20,
      mobile : {
        triggerSize : 'small',
        triggerPositionX : 'left',
        triggerPositionY : 'bottom',
        triggerOffsetX : 5,
        triggerOffsetY : 20,
        triggerRadius : '50%'
      }
    });
  };
  e.appendChild(s);
} ());