<template>
  <div>
    <div v-if="rteLang" class="rte" v-html="rteLang" />
    <div v-if="rteLang" class="rte-spacer" v-html="rteLang" />
    <header id="site-header" class="z-40 opaque careers-header" :class="`${className} ${rteLang ? 'has-rte' : ''}`">
      <div class="primary">
        <div>
          <nuxt-link to="/">
            <img class="brand" alt="Main Event Logo" :src="`${highContrast ? '//images.ctfassets.net/3dar4x4x74wk/GSVc3kvOLQKNnoguJBRZp/e00ce9b83116e4a63217aebd193ff986/Main_Event_Careers-logo.svg' : '//images.ctfassets.net/3dar4x4x74wk/GSVc3kvOLQKNnoguJBRZp/e00ce9b83116e4a63217aebd193ff986/Main_Event_Careers-logo.svg'}`"/>
          </nuxt-link>
        </div>

        <nav class="hidden m-auto">
          <div class="link-wrapper relative" v-for="(link, index) in links" :key="index">
            <nuxt-link v-if="link.fields.internalLink" :to="pageLink(link.fields.url)" exact>{{link.fields.title}}</nuxt-link>
            <a v-else :href="link.fields.url">{{link.fields.title}}</a>
          </div>
        </nav>

        <div class="ml-auto flex items-center toggle-wrapper">
          <div class="social text-center sm:text-right sm:mb-4 sm:w-auto w-full">
            <div v-for="s in social" :key="s.index">
              <a :href="s.fields.url" :title="s.fields.title" target="_blank">
                <img :src="s.fields.image.fields.file.url" :alt="s.fields.image.fields.description" />
              </a>
            </div>
          </div>
          <a class="toggle-menu flex cursor-pointer outline-none ml-auto" @click="toggleMobileMenu">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="21px" height="14px" viewBox="0 0 21 14" enable-background="new 0 0 21 14" xml:space="preserve">
            <g>
              <defs>
                <path id="SVGID_1_" d="M1,0h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.447,2,0,1.553,0,1S0.447,0,1,0z"/>
              </defs>
              <use xlink:href="#SVGID_1_"  overflow="visible" fill-rule="evenodd" clip-rule="evenodd"/>
              <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_"  overflow="visible"/>
              </clipPath>
              <g clip-path="url(#SVGID_2_)">
                <defs>
                  <rect id="SVGID_3_" x="-284" y="-16" width="320" height="4548"/>
                </defs>
                <use xlink:href="#SVGID_3_"  overflow="visible"/>
                <clipPath id="SVGID_4_">
                  <use xlink:href="#SVGID_3_"  overflow="visible"/>
                </clipPath>
                <rect x="-5" y="-5" clip-path="url(#SVGID_4_)" width="31" height="12"/>
              </g>
            </g>
            <g>
              <defs>
                <path id="SVGID_5_" d="M1,6h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.447,8,0,7.553,0,7S0.447,6,1,6z"/>
              </defs>
              <use xlink:href="#SVGID_5_"  overflow="visible" fill-rule="evenodd" clip-rule="evenodd"/>
              <clipPath id="SVGID_6_">
                <use xlink:href="#SVGID_5_"  overflow="visible"/>
              </clipPath>
              <g clip-path="url(#SVGID_6_)">
                <defs>
                  <rect id="SVGID_7_" x="-284" y="-16" width="320" height="4548"/>
                </defs>
                <use xlink:href="#SVGID_7_"  overflow="visible"/>
                <clipPath id="SVGID_8_">
                  <use xlink:href="#SVGID_7_"  overflow="visible"/>
                </clipPath>
                <rect x="-5" y="1" clip-path="url(#SVGID_8_)" width="31" height="12"/>
              </g>
            </g>
            <g>
              <defs>
                <path id="SVGID_9_" d="M1,12h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1c-0.553,0-1-0.447-1-1S0.447,12,1,12z"/>
              </defs>
              <use xlink:href="#SVGID_9_"  overflow="visible" fill-rule="evenodd" clip-rule="evenodd"/>
              <clipPath id="SVGID_10_">
                <use xlink:href="#SVGID_9_"  overflow="visible"/>
              </clipPath>
              <g clip-path="url(#SVGID_10_)">
                <defs>
                  <rect id="SVGID_11_" x="-284" y="-16" width="320" height="4548"/>
                </defs>
                <use xlink:href="#SVGID_11_"  overflow="visible"/>
                <clipPath id="SVGID_12_">
                  <use xlink:href="#SVGID_11_"  overflow="visible"/>
                </clipPath>
                <rect x="-5" y="7" clip-path="url(#SVGID_12_)" width="31" height="12"/>
              </g>
            </g>
            </svg>

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="15.436px" height="15.436px" viewBox="0 0 15.436 15.436" enable-background="new 0 0 15.436 15.436" xml:space="preserve">
            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M1.708,0.293l13.435,13.436c0.39,0.391,0.39,1.023,0,1.414
              c-0.391,0.391-1.024,0.391-1.415,0L0.293,1.707c-0.39-0.391-0.39-1.023,0-1.414C0.684-0.098,1.317-0.098,1.708,0.293z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M0.293,13.729L13.728,0.293c0.391-0.391,1.024-0.391,1.415,0
              c0.39,0.391,0.39,1.023,0,1.414L1.708,15.143c-0.391,0.391-1.024,0.391-1.415,0C-0.097,14.752-0.097,14.119,0.293,13.729z"/>
            </svg>
          </a>
        </div>
      </div>

      <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
        <nav v-show="showMobileMenu" class="mobile absolute lg:hidden">
          <div v-for="(link, index) in links" :key="index">
            <div class="mobile-menu-link-wrapper" @click="toggleMobileMenu" v-if="!link.fields.subLinks">
              <nuxt-link :to="pageLink(link.fields.url)" v-html="link.fields.title"/>
            </div>
            <div class="mobile-menu-link-wrapper" v-else>
              <a v-html="link.fields.title" @click="showSublinks($event)"/>
              <div class="sublinks" v-if="link.fields.subLinks">
                <div @click="toggleMobileMenu">
                  <div v-for="(sublink, subindex) in link.fields.subLinks" :key="subindex">
                    <nuxt-link v-if="sublink.fields.internalLink" :class="index === 0  && 'nuxt-link-exact-active'" :to="subPageLink(sublink.fields.url)">{{sublink.fields.title}}</nuxt-link>
                    <a v-if="sublink.fields.internalLink == false" :class="index === 0  && 'nuxt-link-exact-active'" :href="sublink.fields.url">{{sublink.fields.title}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </transition>
    </header>
  </div>
</template>

<script>
import LocationDropdown from '~/components/locations/LocationDropdown.vue'
import Cart from '~/components/common/Cart.vue'
import moment from 'moment'

const states = require('~/assets/config/states.json')

export default {
  components: {
    LocationDropdown,
    Cart
  },
  data: function() {
    return {
      info: {},
      showMobileMenu: false,
      showLocationDropdown: false,
      showCart: false,
      className: '',
      finding: true,
      locError: false,
      coords: null
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.hideMobileMenu)

    // Transparent header by default
    if (this.$route.name == 'index' || this.$route.name == 'our-story') {
      this.className = 'index'
      setTimeout(() => {
        this.className = 'index transition'
      }, 100)
    }
    // ANSWERS.init({
    //   apiKey: '13c46c2cbef52278daf9a9d34ac27753',
    //   experienceKey: 'main-event-answers',
    //   businessId: '2058308',
    //   experienceVersion: 'PRODUCTION',
    //   locale: 'en',
    //   onReady: function () {
    //     this.addComponent('SearchBar', {
    //       container: '.search-bar',
    //       name: 'search-bar',
    //       redirectUrl: '/search',
    //       placeholderText: 'Search…',
    //       submitIcon: 'magnifying_glass'
    //     });
    //     this.addComponent('SearchBar', {
    //       container: '.search-bar2',
    //       name: 'search-bar2',
    //       redirectUrl: '/search',
    //       placeholderText: 'Search…',
    //       submitIcon: 'magnifying_glass'
    //     });
    //   }
    // })
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.hideMobileMenu);
  },
  watch: {
    '$route.path': function(e) {
      this.current = e
      const header = document.querySelector('careers-header')
      // if (window.location.pathname == '/' || this.$route.name == 'our-story') {
      //   header.classList.add('index')
      // } else {
      //   header.classList.remove('index')
      //   header.classList.add('transition')
      // }
    }
  },
  computed: {
    location() {
      return this.$store.state.location
    },
    locationLink() {
      if(this.location) {
        return `/locations/${states[this.location.state].toLowerCase()}/${this.location.centerName.toLowerCase()}`
      } else {
        return null
      }
    },
    loggedIn() {
      return this.$store.state.loggedIn
    },
    links() {
      return this.$store.state.info.menus.careers.fields.children
    },
    social() {
      return this.$store.state.info.menus.socialAlt.fields.children
    },
    hours() {
      return this.location && this.$store.state.centerData[this.location.centerId] && this.$store.state.centerData[this.location.centerId].hours ? this.$store.state.centerData[this.location.centerId].hours[moment().format('dddd').toLowerCase()] : null
    },
    cart() {
      return this.$store.state.cart
    },
    cartFull() {
      if(this.cart.summary) {
        return 'full'
      } else {
        return null
      }
    },
    // rte() {
    //   if(this.rteLang) {
    //     return 'rte'
    //   } else {
    //     return null
    //   }
    // },
    rteLang() {
      let rte = null
      if((this.location && this.$store.state.centerData[this.location.centerId]) || this.$route.name == 'locations-state-id') {
        let name = null
        let rentals = []
        let addHours = null
        if(this.$route.name == 'locations-state-id') {
          Object.keys(this.$store.state.centerData).map(cid => {
            if(this.$store.state.centerData[cid].path == `${this.$route.params.state}/${this.$route.params.id}/`) {
              name = this.$store.state.centerData[cid].name
              rentals = this.$store.state.centerData[cid].rentals
              addHours = this.$store.state.centerData[cid].addHours
            }
          })
        } else {
          name = this.location.centerName
          rentals = this.$store.state.centerData[this.location.centerId].rentals
          addHours = this.$store.state.centerData[this.location.centerId].addHours
        }
        let now = moment()
        let activeRentals = []
        rentals.map(r => {
          let startDate = moment(r.startDate).add(1, 'day') // until midnight the following day CONSIDER: moment(`${r.startDate} ${r.endTime}`)
          let warnDate = moment(r.startDate).subtract(14, 'days')
          if(now >= warnDate && now < startDate) {
            activeRentals.push({
              date: r.startDate,
              from: r.startTime,
              to: r.endTime
            })
          }
        })
        if(activeRentals.length > 0) {
          activeRentals.sort((a, b) => {
            return a.date < b.date ? -1 : 1
          })
          rte = `${name} will be closed for ${activeRentals.length > 1 ? '' : 'a '}Rent the Center Event${activeRentals.length > 1 ? 's' : ''} on`
          activeRentals.map((r, i) => {
            if(i > 0) {
              rte += ','
            }
            if(activeRentals.length > 1 && i >= activeRentals.length - 1) {
              rte += ' and'
            }
            rte += ` ${r.date} ${r.from}-${r.to}`
          })
        }
        
        if(rte && addHours) {
          rte += `<br />${addHours}`
        } else if(addHours) {
          rte = addHours
        }
      }
      return rte
    },
    highContrast() {
      return this.$store.state.highContrast
    }
  },
  methods: {
    formatTime(hours) {
      if(hours.isClosedWholeDay == true) {
        return 'Closed Today'
      } else {
        let open = hours.openIntervals[0].start.split(':')
        let openTime = moment().hour(open[0]).minute(open[1])
        let closed = hours.openIntervals[hours.openIntervals.length - 1].end.split(':')
        let closedTime = moment().hour(closed[0]).minute(closed[1])
        return `Open Today: ${openTime.format(`h${openTime.minute() > 0 ? ':mm' : ''} a`)} - ${closedTime.format(`h${closedTime.minute() > 0 ? ':mm': ''} a`)}`
      }
    },
    showSublinks(e) {
      let sublinks = document.querySelectorAll('.mobile .sublinks')
      let i
      for (i = 0; i < sublinks.length; i++) {
        sublinks[i].style.display = 'none'
      }
      e.target.nextElementSibling.style.display = 'block'
    },
    async toggleLocationDropdown() {
      this.showLocationDropdown = !this.showLocationDropdown
      this.showCart = false
      if(!this.$store.state.finding && this.showLocationDropdown && !this.$store.state.searched) {
        console.log(navigator.geolocation)
        if(this.$store.state.zipcode) {
          this.$store.commit('finding', true)
          let locations = await this.$api.getLocations({ zipcode: this.$store.state.zipcode })
          this.$store.commit('setLocations', locations)
          this.$store.commit('finding', false)
          this.$store.commit('searched', true)
        } else if(!this.coords && this.$store.state.locations.length < 1 && navigator.geolocation) {
          console.log('get coordinates')
          navigator.geolocation.getCurrentPosition(this.getCoordinates, this.noServices)
          this.$store.commit('finding', true)
        } else if(this.coords) {
          console.log('have coordinates')
          // this.searchCoords()
        } else {
          console.log('must use zipcode')
        }
      }
    },
    getCoordinates(position) {
      this.coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.searchCoords()
    },
    noServices() {
      this.$store.commit('finding', false)
      this.locError = true
    },
    async searchCoords() {
      this.$store.commit('finding', true)
      let locations = await this.$api.getLocations(this.coords)
      this.$store.commit('setLocations', locations)
      this.$store.commit('finding', false)
      this.$store.commit('searched', true)
    },
    toggleMobileMenu() {
      let sublinks = document.querySelectorAll('.mobile .sublinks')
      let i
      for (i = 0; i < sublinks.length; i++) {
        sublinks[i].style.display = 'none'
      }
      let header = document.querySelector('careers-header')
      this.showMobileMenu = !this.showMobileMenu
      if (this.showMobileMenu == true) {
        header.classList.add('mobile-menu-open')
      } else {
        this.showLocationDropdown = false
        this.showCart = false
        header.classList.remove('mobile-menu-open')
        window.scrollTo(window.pageXOffset, window.pageYOffset - 1)
        window.scrollTo(window.pageXOffset, window.pageYOffset + 1)
      }
    },
    hideMobileMenu() {
      this.showMobileMenu = false
      this.showLocationDropdown = false
      this.showCart = false
      let header = document.querySelector('.careers-header')
      if (header) {
        header.classList.remove('mobile-menu-open')
      }
    },
    handleScroll() {
      let header = document.querySelector('careers-header')
      let cart = document.querySelector('.cart')
      // let headerHeight = header.clientHeight
      let headerHeight = 65
      let scrollY = window.scrollY

      // if (scrollY > headerHeight) {
      //   header.classList.add('opaque')
      // } else {
      //   header.classList.remove('opaque')
      // }
    },
    pageLink(link) {
      if(link == '/book/' && this.cart.type) {
        return this.subPageLink(`${link}${this.cart.type}/`)
      } else {
        return link
      }
    },
    subPageLink(link) {
      let newLink = null
      switch(link) {
        case '/book/birthday/':
        case '/book/bowling/':
          if(this.cart.type && link.match(this.cart.type) && this.$store.state.bookFlow) {
            let lastStep = this.$store.state.bookFlow.fields.steps[0].fields.slug
            this.$store.state.bookFlow.fields.steps.map(s => {
              if(this.cart[s.fields.slug] != null) {
                lastStep = s.fields.slug
              }
            })
            newLink = `${link}${lastStep}/`
          } else {
            newLink = link
          }
          break
        default:
          newLink = link
      }
      return newLink
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.fk-menu {
  width: 100%;
  img {
    width: 100%;
    object-fit: cover;
  }
}


#site-header.mobile-menu-open {
  background: rgba(31, 31, 30,1)!important;

  .toggle-menu {
    svg:first-of-type {
      display: none;
    }
    svg:last-of-type {
      display: block;
    }
  }

  .location {
    margin-top: 10px;
    margin-bottom: 15px;

    .location-icon img {
      height: 20px;
      min-width: 0;
    }

    .location-title {
      padding-left: 8px;
    }

    .location-dropdown-icon {
      cursor: pointer;
    }
  }
}

#site-header.index {
  background: none;
  transition: all 0s;
}

#site-header.transition {
  transition: all .3s;
}

.link-wrapper .nuxt-link-active:after {
  content: '';
  border-bottom: 2px solid #fff;
  position: absolute;
  bottom: 7px;
  left: 12px;
  right: 12px;

  @media (max-width: 1130px) {
    left: 10px;
    right: 10px;
  }
}

.sublinks .nuxt-link-active:after {
  display: none;
}

#site-header {
  position: absolute;
}

#site-header.opaque {
  background: #1F1F1E;
  @media (min-width: $md) {
    background: linear-gradient(to top, transparent 0%, black 100%)!important;
  }
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @media (max-width: 742px) {
    // margin:20px 0 30px 0;
    justify-content: center;

    a img {
      width: 30px;
    }
  }

  div {
    margin-right: 12px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  img {
    width: 37px;
  }
}

#site-header.careers-header {
  .brand {
    width: 125px;
    @media (min-width: $sm) {
      width: 175px;
    }
  }
  .primary {
    padding: 0 20px;
    justify-content: space-between;
    @media (min-width: $md) {
      padding: 0 50px;
    }
    nav {
      margin: 0;
      padding: 0;
      .link-wrapper {
        &:first-of-type {
          a {
            text-decoration: none!important;
            background: none;
            color: white!important;

            &:hover {
              color: #1F1F1E!important;
            }
          }
        }
      }
    }
    .toggle-wrapper {
      margin: 0;
    }
  }
  nav.mobile {
    div:nth-of-type(2) {
      .mobile-menu-link-wrapper {
        a {
          background: none;
          color: white!important;
          padding: 0;
        }
      }
    }
  }
}
</style>