<template>
<div class="uppercase text-center">
  <p v-if="message" v-html="message" />
  <p v-else>We apologize for the inconvenience! It looks like something went wrong, please try again.</p>
  <p v-if="tryAgain" class="pt-4">
    <a href="#tryAgain" v-on:click.prevent="tryAgain" class="underline" v-html="tryMessage || 'Try Again'" />
  </p>
</div>
</template>

<script>
export default {
  props: [
    'message',
    'tryAgain',
    'tryMessage'
  ]
}
</script>