<template>
  <error-main />
</template>

<script>
import ErrorMain from '~/components/404/Main'

export default {
  components: {
    ErrorMain
  },
  head: function() {
    return {
      title: `Page Not Found`
    }
  }
}
</script>
