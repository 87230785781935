<template>
  <transition enter-active-class="animated fadeInRight" leave-active-class="animated fadeOutRight">
    <div class="w-full max-w-md cart" v-show="show">
      <img class="cursor-pointer close" src="~/assets/img/icons/close.svg" alt="Close Icon" @click="close"/>
      <div class="mb-2 text-lg">Your Cart</div>
      <div v-if="show && cart" :class="`relative font-normal pt-4 ${loading || error ? 'loading' : ''}`">
        <div class="scroller scroller-white">
          <book-cart color="white" />
        </div>
        <div class="flex justify-end pt-4">
          <div class="mr-2">
            <a class="button" :href="`/book/${cart.type}/summary/`" v-on:click.prevent="viewCart">View Cart</a>
          </div>
          <div>
            <a class="button" :href="checkoutURL" v-on:click.prevent="redirectCheckout">Checkout</a>
          </div>
        </div>
      </div>
      <div v-else class="font-normal">Your cart is empty</div>
      <div v-if="loading" class="absolute inset-0 flex items-center justify-center mt-6">
        <loader />
      </div>
      <div v-else-if="error" class="absolute inset-0 flex items-center justify-center px-4 mt-6">
        <error-basic :message="errorMsg" />
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment'
import Loader from './Loader'
import ErrorBasic from './ErrorBasic'
import BookCart from '../book/BookCart'

export default {
  components: {
    Loader,
    ErrorBasic,
    BookCart
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMsg: null
    }
  },
  mounted() {
    // window.addEventListener("click", this.handleClick)
  },
  destroyed() {
    // window.removeEventListener("click", this.handleClick)
  },
  computed: {
    cart() {
      return this.$store.state.cart
    },
    checkoutURL() {
      return this.cart.summary && this.cart.summary.redirectUrls ? '#checkout' : null
    }
  },
  methods: {
    close() {
      this.$parent.toggleCart()
    },
    handleClick(e) {
      let cart = document.querySelector('.cart')
      let cartIcon = document.querySelector('.cart-icon')
      let clickedOutsideCart = !((e.target == cart) || cart.contains(e.target))
      let clickedCartIcon = ((e.target == cartIcon) || cartIcon.contains(e.target))
      this.loading = false
      this.error = false
      if (this.show && clickedOutsideCart && !clickedCartIcon) {
        this.close()
      }
    },
    viewCart() {
      this.$router.push(`/book/${this.cart.type}/summary/`)
      this.$store.commit('cart/viewCart', true)
      this.close()
    },
    async redirectCheckout() {
      if(this.cart.type == 'birthday') {
        let until = moment(`${this.cart.time.date} ${this.cart.time.eventPackageStartTime}`).diff(moment(), 'hours')
        if(until < 72) {
          this.errorMsg = 'Birthday Party Bookings require a Minimum 72 hour notice. Please select a different date or time.'
          this.error = true
          return
        }
      } else if(this.cart.type == 'bowling') {
        let until = moment(`${this.cart.details.date} ${this.cart.time.reservationStartTime}`).diff(moment(), 'hours')
        console.log('until', until)
        if(until < 1) {
          this.errorMsg = 'Bowling Lanes require a Minimum 1 hour notice. Please select a different date or time.'
          this.error = true
          return
        }
      }
      this.loading = true
      let link = null
      if(this.$store.state.account) {
        link = true
        // link = await this.$mulesoftv3.getCustomerRedirectUrls(this.$store.state.account.accountId, this.cart.location.centerId, this.cart.summary.id)
        // .then(res => {
        //   return res.data
        // }).catch(err => {
        //   if(err.response.status == 401) {
        //     localStorage.setItem('me_redirect', `/book/${this.cart.type}/summary/`)
        //     this.$router.push('/login/')
        //     this.close()
        //   } else {
        //     this.error = true
        //     console.error(err)
        //   }
        //   return null
        // })
      } else {
        link = true
        // link = await this.$mulesoftv3.getRedirectUrls(this.cart.location.centerId, this.cart.summary.id)
        // .then(res => {
        //   return res.data
        // }).catch(err => {
        //   this.error = true
        //   console.error(err)
        //   return null
        // })
      }
      if(link) {
        this.$router.push('/book/checkout')
        // window.location = link.checkoutUrl
      } else {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scroller {
  height: calc(100vh - 300px);
}
.loading {
  opacity: .15;
}
</style>
