export default (context, inject) => {
  const content = {
    async getEntries(type) {
      let entries = []
      let sitemap = process.server ? await context.$storage.getFile('sitemap') : context.store.state.sitemap
      for(var l in sitemap) {
        let loc = sitemap[l]
        if(loc.type == type) {
          let entry = await this.getEntryById(loc.id)
          if(entry) {
            entries.push(entry)
          }
        }
      }
      return entries
    },
    async getEntry(type, slug) {
      let entry = null
      let entryId = null
      context.store.state.sitemap.map(l => {
        if(l.type == type && l.slug == slug) {
          entryId = l.id
        }
      })
      if(entryId) {
        entry = await this.getEntryById(entryId)
      }
      return entry
    },
    async getEntryById(id) {
      let entry = null
      if(process.server) {
        entry = await context.$storage.getFile(`_entries/${id}`)
      } else {
        try {
          entry = JSON.parse(JSON.stringify(context.store.state.entries[id]))
        } catch(err) {
          console.error(err)
          entry = null
        }
      }
      if(entry) {
        entry.fields = await this.getFields(entry.fields)
      }
      return entry
    },
    async getFields(fields) {
      let object = {}
      let keys = Object.keys(fields)
      for(var k in keys) {
        let key = keys[k]
        if(Array.isArray(fields[key])) {
          object[key] = await this.getArray(fields[key])
        } else {
          object[key] = fields[key]
        }
      }
      return object
    },
    async getArray(array) {
      let children = []
      for(var c in array) {
        let child = array[c]
        if(child.sys) {
          children.push(await this.getEntryById(child.sys.id))
        } else {
          children.push(child)
        }
      }
      return children
    }
  }
  inject('contentful', content)
  context.$contentful = content
}

