<template>
  <div :class="`${routeName} ${highContrast ? 'high-contrast' : ''}`">
    <Header />
    <main class="flex items-stretch justify-between" :style="'min-height:'+ (mainHeight > 0) ? mainHeight+'px' : `calc(100vh - ${footerHeight})`">
      <nuxt/>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/careers/Header.vue'
import Footer from '../components/careers/Footer.vue'
import products from '~/assets/config/gaProducts.json'

export default {
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      footerHeight: 317,
      mainHeight: this.$store.state.mainHeight,
      routeName: '',
      products: products
    }
  },
  computed: {
    info() {
      return this.$store.state.info
    },
    highContrast() {
      return this.$store.state.highContrast
    }
  },
  mounted: async function() {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('code')) {
        let barcodeWrapper = document.querySelector('[id^=easter-egg] .max-w-2xl p:first-of-type')
        if (barcodeWrapper == null ) barcodeWrapper = document.querySelector('[id^="4th-of-july-surprise"] .max-w-2xl p:first-of-type')
        if (barcodeWrapper == null ) barcodeWrapper = document.querySelector('[id^="national-friendship-day"] .max-w-2xl p:first-of-type')
        if (barcodeWrapper == null ) barcodeWrapper = document.querySelector('[id^="national-smores-day"] .max-w-2xl p:first-of-type')
        if (barcodeWrapper == null ) barcodeWrapper = document.querySelector('[id$="-promo"] .max-w-2xl p:first-of-type')
        const img = document.createElement("img")
        img.src = 'https://barcode.mainevent.com/barcode_me/index.html?code=' + urlParams.get('code')
        barcodeWrapper.appendChild(img)
      }
    } catch(err) {
      // console.error(err)
    }
    try {
      await this.$recaptcha.init()
    } catch(err) {
      // console.error(err)
    }
    let login = await this.$okta.checkLogin()
    window.dataLayer = window.dataLayer || []
    if(!login && this.$route.query.accessToken) {
      let user = this.$mulesoftv3.getAccount()
      .then(res => {
        this.$store.commit('setAccount', res.data.accounts[0])
        this.$store.commit('setLogInState', true)
        this.$store.commit('accessToken', this.$route.query.accessToken)
        window.dataLayer.push({
          me_account: res.data.accounts[0]
        })
      }).catch(err => {
        console.error(err)
      })
    } else if(login) {
      try {
        window.dataLayer.push({
          me_account: this.$store.state.account
        })
      } catch(err) {
        // console.error(err)
      }
    }
    if(this.$route.query.clientId) {
      this.$store.commit('clientId')
    }

    this.gtm(this.$route) // GTM data layer on mounted

    this.$nextTick(function() {
      this.getMainHeight()
      window.addEventListener('resize', this.getMainHeight)
      setTimeout(function() {
        window.dispatchEvent(new CustomEvent('scroll'))
      }, 100)

      this.routeName = this.$route.name
    })
  },
  watch: {
    '$route.path': function(e) {
      setTimeout(function() {
        window.dispatchEvent(new CustomEvent('scroll'))
        this.gtm(this.$route) // GTM data layer on route change
      }.bind(this), 100)

      this.routeName = this.$route.name
    }
  },
  methods: {
    gtm(route) {
      if(!route.path.match(/^\/book\/confirm/)) {
        let pageviewDetails = {
          event: 'pageview',
          ecommerce: null
        }
        let path = route.fullPath
        let product = products[path]
        if (product !== undefined) {
          // console.log(product)
          let id = product[0]
          let name = product[1]
          let category = product[2]
          pageviewDetails.ecommerce = {
            'impressions': [
              {
                'name': name,
                'id': id,
                'category': category
              }
            ]
          }
          pageviewDetails.cartContent = null
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(pageviewDetails)
      }
    },
    getMainHeight() {
      const clientHeight = document.documentElement.clientHeight
      const footerHeight = document.querySelector('footer').clientHeight
      const height = clientHeight - footerHeight
      this.setMainHeight(height)
      this.footerHeight = document.querySelector('footer').clientHeight
    },
    setMainHeight(height) {
      this.mainHeight = height
      this.$store.commit('setMainHeight', height)
    },
    toggleCart(e) {
      alert('Toggle Cart')
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getMainHeight)
  }
}
</script>

<style lang="scss">
@import '../assets/scss/variables';

main > * {
  width: 100%;
}

[id^=easter-egg] .max-w-2xl img, 
[id^="4th-of-july-surprise"] .max-w-2xl img,
[id^="national-smores-day"] .max-w-2xl img,
[id$="-promo"] .max-w-2xl img,
[id^="national-friendship-day"] .max-w-2xl img {
  margin: -5px auto 25px auto;
  transform: scale(1.15);
}

.high-contrast {
  .book-select,
  .booking-button,
  button.purple,
  .button.purple,
  button.pink,
  .button.pink,
  .contact,
  .section-heading,
  .section-heading.purple,
  .special-banner,
  .article-card .article-card-body,
  .menu-nav button:hover,
  .menu-nav button.active {
    background-color: $dark !important;
    border-color: $dark;
  }
  button.purple:hover,
  .button.purple:hover,
  button.pink:hover,
  .button.pink:hover,
  .event-packages .overflow-x-scroll button.magenta,
  .article-card button {
    background-color: white !important;
    color: $dark !important;
  }
  button,
  .button,
  .feature-slide-text .button,
  .experience-slide-text .button {
    border: 3px solid white;
    &:hover {
      background: $dark !important;
    }
  }
  .event-packages .overflow-x-scroll button.magenta {
    border-color: $dark;
  }
  .experience-list .experience-item .button,
  .bday-package-slider .bday-package-card a,
  .event-packages .overflow-x-scroll button:not(.magenta),
  .article-card button,
  &.gift-cards .details-section-details div p a {
    border-color: white !important;
    background: $dark !important;
    color: white !important;

    &:hover {
      border-color: $dark !important;
      background: white !important;
      color: $dark !important;
    }
  }
  #locations,
  .feature-slide-text,
  .experience-slide-text,
  .contact,
  .bday-package-card .best-value,
  .book-location-item,
  .bg-blue,
  .bg-purple,
  .bg-pink {
    background: $dark !important;
  }
  .border-blue,
  .border-purple,
  .border-pink {
    border-color: $dark !important;
  }
  .text-purple,
  .book-package-item.selected,
  button.purple.selected {
    color: $dark !important;
  }
  .counter-button:hover:before,
  .counter-button:hover:after,
  button.purple.selected {
    background: white !important;
  }
  .time-select button {
    &:hover {
      color: white !important;
    }
    &[disabled] {
      border-color: #9A9A99 !important;
    }
  }
  .feature-slider .slick-dots,
  .experience-slider .slick-dots {
    button {
      box-shadow: 0px 0px 0px 3px black;
      background: $dark !important;
    }
    .slick-active button {
      background: white !important;
    }
  }
  #site-header {
    &.index {
      background: $dark !important;
    }
    .primary nav .link-wrapper {
      & > a:hover,
      .sublinks a:hover {
        background: white !important;
        color: $dark !important;
      }
    }
  }
}
</style>
