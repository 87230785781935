<template>
<div class="w-full uppercase">
  <div v-if="cart">
    <div>
      <div :class="`border-b-2 ${borderColor} mb-4`">
        <div class="pb-3 font-bold text-left cart-title" v-html="cart.title || ''" />
      </div>
      <div class="flex-wrap justify-between hidden font-bold lg:flex">
        <div class="w-full py-3 text-left lg:w-auto lg:flex-grow">Item</div>
        <div class="w-1/3 py-3 text-right lg:w-12">Qty</div>
        <div class="w-1/3 py-3 text-right lg:w-24">Unit Price</div>
        <div class="w-1/3 py-3 text-right lg:w-24">Total</div>
      </div>
    </div>
    <div :class="`border-b-2 ${borderColor}`">
      <div v-for="(c, i) in cart.items" :key="i" class="flex cart-item">
        <div :class="`${i < cart.items.length - 1 ? 'lg:pb-3' : 'lg:pb-4'} w-full lg:w-1/2 lg:flex-grow`" v-html="c.name" />
        <div :class="`${i < cart.items.length - 1 ? 'pb-3' : 'pb-4'} w-1/3 lg:w-12 text-right`">
          <div class="font-bold lg:hidden" v-if="c.quantity">QTY</div>
          <div v-html="!c.coupon ? c.quantity : ''" />
        </div>
        <div :class="`${i < cart.items.length - 1 ? 'pb-3' : 'pb-4'} w-1/3 lg:w-24 text-right`">
          <div class="font-bold lg:hidden" v-if="c.price">PRICE</div>
          <div v-html="c.price && !c.coupon ? formatPrice(c.price) : ''" />
        </div>
        <div :class="`${i < cart.items.length - 1 ? 'pb-3' : 'pb-4'} w-1/3 lg:w-24 text-right`">
          <div class="font-bold lg:hidden" v-if="c.price">TOTAL</div>
          <div v-html="c.price ? formatPrice(c.price * c.quantity) : ''" />
        </div>
      </div>
      <div v-if="coupon" class="flex justify-between">
        <div class="w-full pb-3">
          <div v-if="cart.coupon" class="flex justify-between w-full">
            <div class="">COUPON</div>
            <div class="text-right" v-html="formatPrice(cart.coupon)" />
          </div>
          <div v-else-if="couponError" class="flex w-full text-red">
            <div class="">Coupon Not Valid <a v-on:click.prevent="removeCouponError()" href="#try-again" class="pl-2 text-xs">try again</a></div>
          </div>
          <form v-else-if="couponField" class="flex w-full" v-on:submit.prevent="submitCouponCode()">
            <div class="w-64">
              <label for="couponCode" class="hideme">Coupon Code</label>
              <input id="couponCode" name="couponCode" type="text" placeholder="Coupon Code" class="w-full pb-2 border-b-2 border-black" v-model="couponCode" />
            </div>
            <div class="flex pl-2 justify-right">
              <div>
                <button class="pink">Add Coupon</button>
              </div>
              <div class="pl-2">
                <button class="purple" v-on:click="showCouponField(false)">Cancel</button>
              </div>
            </div>
          </form>
          <div v-else><a href="#add-coupon" class="font-bold text-purple" v-on:click.prevent="showCouponField(true)">Add Coupon +</a></div>
        </div>
        <div class="pt-3 text-right"></div>
      </div>
    </div>
    <div>
      <div class="flex justify-between">
        <div class="pt-3">Subtotal</div>
        <div class="pt-3 text-right" v-html="formatPrice(cart.subtotal)" />
      </div>
      <div v-for="t in cart.taxes" :key="t.index" class="flex justify-between">
        <div class="pt-3" v-html="t.name" />
        <div v-if="type === 'birthday' && t.name === 'Tax'" class="pt-3 text-right" v-html="'TBD'" />
        <div v-else class="pt-3 text-right" v-html="formatPrice(t.total)" />
      </div>
      <div v-if="cart.discount" class="flex justify-between">
        <div class="pt-3">Discount</div>
        <div class="pt-3 text-right" v-html="formatPrice(cart.discount)" />
      </div>
      <div v-if="cart.total" class="flex justify-between font-bold">
        <div class="pt-3">Total</div>
        <div v-if="type === 'birthday'" class="pt-3 text-right" v-html="'TBD'" />
        <div v-else class="pt-3 text-right" v-html="formatPrice(cart.total)" />
      </div>
      <div v-if="cart.deposit" class="flex justify-between font-bold">
        <div class="pt-3">Deposit Due</div>
        <div class="pt-3 text-right" v-html="`- ${formatPrice(cart.deposit)}`" />
      </div>
      <div v-if="cart.deposit" class="flex justify-between">
        <div class="pt-3">Remaining Balance</div>
        <div v-if="type === 'birthday'" class="pt-3 text-right" v-html="'TBD'" />
        <div v-else class="pt-3 text-right" v-html="formatPrice(cart.total - cart.deposit)" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: [
    'color',
    'coupon',
    'couponError',
    'onCouponCode',
    'onCouponCodeRemove',
    'onCouponErrorClear'
  ],
  data() {
    return {
      couponField: false,
      couponCode: null,
      testCoupon: false
    }
  },
  computed: {
    borderColor() {
      switch(this.color) {
        case 'white':
          return 'border-white'
          break
        default:
          return 'border-black'
      }
    },
    cart() {
      return this.$store.state.cart.overview
    },
    type() {
      return this.$store.state.cart.type
    }
  },
  mounted() {
    if(this.couponError) {
      this.showCouponField(true)
    }
  },
  methods: {
    formatPrice(p) {
      return `${p > -1 ? '' : '-'} $${Math.abs(p).toFixed(2).replace(/(\d)(\d{3})\./, '$1,$2.')}`
    },
    showCouponField(show) {
      this.couponField = show
    },
    submitCouponCode() {
      this.onCouponCode(this.couponCode)
    },
    removeCouponCode() {
      // this.onCouponCodeRemove()
      this.testCoupon = false
    },
    removeCouponError() {
      this.onCouponErrorClear()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/_variables.scss';
label.hideme {
  font-size: 0;
  float: left;
}

.cart-item {
  > div:first-of-type {
    max-width: 174px;
  }
}

.season-cart {
  .cart-item {
    @apply gap-4;

    @media (min-width: $lg) {
      @apply gap-0;
    }

    > div:nth-child(2) {
      @apply ml-auto;
    }
  }
}
</style>
