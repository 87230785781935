<template>
  <div :class="routeName">
    <Header />
    <main class="flex items-stretch justify-between" :style="`min-height: calc(100vh - ${footerHeight}px)`">
      <nuxt/>
    </main>
  </div>
</template>

<script>
import Header from '~/components/common/HeaderMinimal.vue'

export default {
  components: {
    Header
  },
  data: function() {
    return {
      footerHeight: 0,
      routeName: ''
    }
  },
  watch: {
    '$route.path': function(e) {
      setTimeout(function() {
        window.dispatchEvent(new CustomEvent('scroll'))
      }, 100)
      this.routeName = this.$route.name
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      setTimeout(function() {
        window.dispatchEvent(new CustomEvent('scroll'))
      }, 100)
    })
  }
}
</script>

<style lang="scss">
main > * {
  width: 100%;
}
</style>
