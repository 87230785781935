export default (context, inject) => {
  const mulesoftv3 = {
    async request(obj) {
      let origin = `${process.env.API_URL || `${window.location.origin}/api`}/mulesoft/`
      // let origin = 'https://api-gw.mainevent.com/api-v3/v3/'
      obj.url = origin + obj.url
      if (!obj.params) {
        obj.params = {}
      }
      if (process.env.API_DEV || process.env.MULESOFT_CLIENT_ID || context.query.clientId) {
        obj.params.api = process.env.API_DEV
        // if (process.env.API_DEV) {
        //   if (!obj.params.api) {
        //     obj.params.api = process.env.API_DEV
        //   } else {
        //     obj.params.api = `${process.env.API_DEV}${obj.params.api}`
        //   }
        // }
        if (context.store.state.clientId) {
          obj.params.client_id = context.store.state.clientId
        } else if (context.query.clientId) {
          obj.params.client_id = context.query.clientId
          // } else if(process.env.MULESOFT_CLIENT_ID) {
          //   obj.params.client_id = process.env.MULESOFT_CLIENT_ID
        }
      }
      if (obj.verify) {
        if(!obj.headers) {
          obj.headers = {}
        }
        obj.headers.verify = await context.$recaptcha.execute("login")
        delete obj.verify
      }
      return context.$axios(obj)
    },
    async requestFunCard(obj) {
      let origin = `${process.env.API_URL || `${window.location.origin}/api`}/funcards/`
      obj.url = origin + obj.url
      if (!obj.params) {
        obj.params = {}
      }
      if (process.env.API_DEV || process.env.MULESOFT_CLIENT_ID || context.query.clientId) {
        if (process.env.API_DEV) {
          obj.params.api = process.env.API_DEV
        }
        if (context.store.state.clientId) {
          obj.params.client_id = context.store.state.clientId
        } else if (context.query.clientId) {
          obj.params.client_id = context.query.clientId
        } else if (process.env.MULESOFT_CLIENT_ID) {
          obj.params.client_id = process.env.MULESOFT_CLIENT_ID
        }
      }
      if (!obj.headers) {
        obj.headers = {
          verify: await context.$recaptcha.execute('login')
        }
      }
      return context.$axios(obj)
    },
    async getAuth(obj = {}) {
      let accessToken = null
      if (context.store.state.accessToken) {
        accessToken = context.store.state.accessToken
      } else if (context.query.accessToken) {
        accessToken = context.query.accessToken
      } else {
        accessToken = await context.$okta.getAccessToken()
      }
      // obj.authorization = `Bearer ${accessToken}`
      obj['x-auth-token'] = `${accessToken}`
      return obj
    },
    // // Signup
    async submitContact(obj) {
      return this.request({
        method: 'post',
        url: 'contacts',
        data: obj,
        verify: true,
      })
    },

    // Lead gen
    async leadGen(data) {
      return this.request({
        method: "post",
        url: "eventLeads",
        data: data,
        verify: true
      })
    },

    // // Account access
    async getAccount() {
      return this.request({
        method: 'get',
        url: `accounts`,
        headers: await this.getAuth()
      })
    },
    async updateAccount(id, obj) {
      return this.request({
        method: 'patch',
        url: `accounts/${id}`,
        data: obj,
        headers: await this.getAuth()
      })
    },
    // // Customer Orders
    async createCustomerCart(id, centerId, obj) {
      return this.request({
        method: 'post',
        url: `accounts/${id}/orders/${centerId}/carts`,
        headers: await this.getAuth(),
        data: obj
      })
    },
    async addCustomerCoupon(id, centerId, cartId, obj) {
      return this.request({
        method: 'post',
        url: `accounts/${id}/orders/${centerId}/carts/${cartId}/promotions`,
        headers: await this.getAuth(),
        data: obj
      })
    },
    // async getCustomerRedirectUrls(id, centerId, cartId) {
    //   return this.request({
    //     method: 'post',
    //     url: `accounts/${id}/orders/${centerId}/carts/${cartId}/redirect-urls`,
    //     headers: await this.getAuth()
    //   })
    // },
    async deleteCustomerCart(id, centerId, cartId) {
      return this.request({
        method: 'delete',
        url: `accounts/${id}/orders/${centerId}/carts/${cartId}`,
        headers: await this.getAuth()
      })
    },
    // // Orders
    async createCart(obj) {
      return this.request({
        method: 'post',
        url: `carts`,
        headers: await this.getAuth(),
        verify: true,
        data: obj
      })
    },
    async addToCart(cartId, obj) {
      return this.request({
        method: 'post',
        url: `carts/${cartId}/items`,
        headers: await this.getAuth(),
        verify: true,
        data: obj
      })
    },
    async createTeamCart(obj) {
      return this.request({
        method: 'post',
        url: `carts`,
        data: obj,
        verify: true,
        headers: await this.getAuth()
      })
    },
    // getCart(centerId, cartId) {
    //   return this.request({
    //     method: 'get',
    //     url: `orders/${centerId}/carts/${cartId}`
    //   })
    // },
    // updateCart(centerId, cartId, obj) {
    //   return this.request({
    //     method: 'put',
    //     url: `orders/${centerId}/carts/${cartId}`,
    //     data: obj
    //   })
    // },
    addCoupon(cartId, obj) {
      return this.request({
        method: 'post',
        url: `carts/${cartId}/promotions`,
        data: obj
      })
    },
    // getRedirectUrls(centerId, cartId) {
    //   return this.request({
    //     method: 'post',
    //     url: `orders/${centerId}/carts/${cartId}/redirect-urls`
    //   })
    // },
    deleteCart(centerId, cartId) {
      return this.request({
        method: 'delete',
        url: `orders/${centerId}/carts/${cartId}`
      })
    },
    // Reservations
    async createReservation(obj) {
      return this.request({
        method: 'post',
        url: 'reservations',
        data: obj
      })
    },
    getReservation(id) {
      return this.request({
        method: 'get',
        url: `reservations/${id}`
      })
    },
    getAllAvailabilities(obj) {
      return this.request({
        method: 'get',
        url: 'reservations/availabilities',
        params: obj
      })
    },
    getActivities(obj) {
      return this.request({
        method: 'get',
        url: `reservations/activities`,
        params: obj
      })
    },
    getActivityAvailabilities(activityId, obj) {
      return this.request({
        method: 'get',
        url: `reservations/activities/${activityId}/availabilities`,
        params: obj
      })
    },
    getReservationAddOns(obj) {
      return this.request({
        method: 'get',
        url: 'reservations/add-ons',
        params: obj
      })
    },
    getNewAddOns(obj) {
      return this.request({
        method: 'get',
        url: 'funCards/products',
        params: obj
      })
    },
    getReservationOptions(obj) {
      return this.request({
        method: 'get',
        url: 'reservations/options',
        params: obj
      })
    },
    // Packages
    getEventPackages(centerId) {
      return this.request({
        method: 'get',
        url: 'events/packages',
        params: {
          locationId: centerId
        }
      })
    },
    getEventPackageFunctions(centerId) {
      return this.request({
        method: 'get',
        url: 'events/packages/activities',
        params: {
          locationId: centerId
        }
      })
    },
    getEventPackage(packageCode, desiredDate, desiredTime) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}`,
        params: {
          desiredStartDate: desiredDate,
          desiredStartTime: desiredTime
        }
      })
    },
    getEventPackageAvailability(packageCode, obj) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/availabilities`,
        params: obj
      })
    },
    getEventPackageMenu(packageCode, desiredDate, desiredTime, locationId) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/menus`,
        params: {
          locationId,
          startDate: desiredDate,
          startTime: desiredTime
        }
      })
    },
    getEventPackageActivities(packageCode) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/activities`
      })
    },
    getEventPackageExtras(packageCode, startDate, startTime, locationId) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/extras`,
        params: {
          locationId,
          startDate,
          startTime
        }
      })
    },
    getEventPackagePartyCity(packageCode, startDate, startTime, locationId) {
      return this.request({
        method: 'get',
        url: `events/packages/${packageCode}/pcProducts`,
        params: {
          locationId,
          startDate,
          startTime
        }
      })
    },
    createEventPackageQuote(obj) {
      return this.request({
        method: 'post',
        url: 'events/quotes',
        data: obj
      })
    },
    createEventPackageCart(obj) {
      return this.request({
        method: 'post',
        url: 'events/packages/bookings',
        data: obj
      })
    },
    // Checkout
    createCheckout(centerId, cartId, obj) {
      return this.request({
        method: 'post',
        url: `orders/${centerId}/carts/${cartId}/checkout`,
        data: obj,
        verify: true,
      })
    },
    createZeroTotalCheckout(locationId, cartId, obj) {
      return this.request({
        method: 'post',
        url: `carts/${cartId}/checkout`,
        data: obj,
        verify: true,
        params: {
          locationId: locationId
        }
      })
    },
    submitPayment(obj) {
      return this.request({
        method: 'post',
        url: 'payments',
        data: obj,
        verify: true,
      })
    },
    // // Fun Cards
    funCardBalance(funcardNumber, pin) {
      return this.requestFunCard({
        method: 'get',
        url: `funCards/${funcardNumber}/balance`,
        params: {
          pin: pin
        }
      })
    },
    // Center
    getCenter(id) {
      return this.request({
        method: 'get',
        url: `centers/${id}`,
        params: {
          includeVenues: true,
          includeEvents: true
        }
      })
    },
    getCenterProducts(id) {
      return this.request({
        method: 'get',
        url: `centers/${id}/products`
      })
    },
    // Leagues
    getLeagues(centerId) {
      return this.request({
        method: 'get',
        url: 'leagues',
        params: {
          locationId: centerId
        }
      })
    },
    getLeagueSeasons(locationId, leagueId) {
      return this.request({
        method: 'get',
        url: `leagues/${leagueId}/seasons`,
        params: {
          locationId: locationId
        }
      })
    },
    getLeagueSeasonsSlots(leagueId, seasonId) {
      return this.request({
        method: 'get',
        url: `leagues/${leagueId}/seasons/${seasonId}/slots`,
      })
    },
    getLeagueCapacity(centerId) {
      return this.request({
        method: 'get',
        url: 'leagues/availability/leagueCapacity',
        params: {
          centerId
        }
      })
    },
    getTeamName(teamName) {
      return this.request({
        method: 'get',
        url: `teams`,
        params: {
          name: teamName
        }
      })
    },
    getLeagueTeam(inviteCode) {
      return this.request({
        method: 'get',
        url: `teamRegistrations`,
        params: {
          inviteCode
        }
      })
    },
    // getTeamCapacity(teamId) {
    //   return this.request({
    //     method: 'get',
    //     url: `leagues/availability/teamCapacity`,
    //     params: {
    //       teamId
    //     }
    //   })
    // },
    createTeam(teamInfo) {
      return this.request({
        method: 'post',
        url: `leagues/registration/team`,
        data: teamInfo
      })
    },
    addBowler(bowlerInfo) {
      console.log(bowlerInfo)
      return this.request({
        method: 'post',
        url: `leagues/registration/team/${bowlerInfo.teamId}`,
        data: bowlerInfo
      })
    },
    createBowler(bowlerInfo) {
      return this.request({
        method: 'post',
        url: `leagues/registration`,
        data: bowlerInfo
      })
    },
    getLeagueScore(leagueId,leagueSeason) {
      return this.request({
        method: 'get',
        url: `leagues/${leagueId}/seasons/${leagueSeason}/topScores`
      })
    },
    //Season pass end points
    registerSeasonPass(data) {
      return this.request({
        method: 'post',
        url: `seasonpass/register`,
        data: data
      })
    },
    // Dining end points
    getDiningSections(data) {
      return this.request({
        method: 'get',
        url: 'reservations/tables',
        params: data
      })
    },
    getDiningAvailabilities(data) {
      return this.request({
        method: 'get',
        url: 'reservations/tables/availabilities',
        params: data
      })
    },
    createDiningReservation(data) {
      return this.request({
        method: 'post',
        url: `reservations/tables`,
        data: data,
      })
    },
  }

  inject('mulesoftv3', mulesoftv3)
  context.$mulesoftv3 = mulesoftv3
}
