<template>
  <footer>
    <div class="w-full px-4 md:px-10 flex flex-col items-center justify-center">

      <div class="w-full flex flex-col md:flex-row items-center md:items-start justify-between">

        <div class="mb-4 md:m-0">
          <div class="flex flex-col items-center md:items-start justify-center">
            <nuxt-link to="/"><img class="brand" alt="Main Event Logo" :src="`${highContrast ? '//images.ctfassets.net/3dar4x4x74wk/6Ymugi2K8gWZMhGmcFko8m/a140cee52691ba620d1a7f07af0d5cab/me-careers-footer-logo.svg' : '//images.ctfassets.net/3dar4x4x74wk/6Ymugi2K8gWZMhGmcFko8m/a140cee52691ba620d1a7f07af0d5cab/me-careers-footer-logo.svg'}`"/></nuxt-link>
          </div>
        </div>


        <div class="w-full mx-auto md:mx-0 flex flex-col items-start md:items-end justify-center">
          <div class="w-full flex justify-between md:justify-end">
            <div class="md:pl-8 mb-4 md:mb-8" v-for="link in links" :key="link.index">
              <nuxt-link v-if="link.fields.internalLink" :to="link.fields.url" v-html="link.fields.title" />
              <a v-else :href="link.fields.url" v-html="link.fields.title" />
            </div>
          </div>

          <div class="social text-left sm:text-right sm:mb-4 sm:w-auto w-full">
            <div v-for="s in social" :key="s.index">
              <a :href="s.fields.url" :title="s.fields.title" target="_blank">
                <img :src="s.fields.image.fields.file.url" :alt="s.fields.image.fields.description" />
              </a>
            </div>
          </div>
        </div>

      </div>

      <div class="w-full flex flex-col md:flex-row items-center justify-center md:justify-start">
        <div class="flex items-center justify-center copyright mt-8 mb-2 md:m-0">
          {{copyright}}
        </div>
        <div class="w-full md:w-auto flex flex-row items-center justify-center md:justify-start copyright">
          <p class="copyright mx-2">|</p>
          <nuxt-link class="copyright hover:underline" to="/terms">Terms &amp; Conditions</nuxt-link>
          <p class="copyright mx-2">|</p>
          <nuxt-link class="copyright hover:underline" to="/privacy-policy">Privacy Policy</nuxt-link>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    links: function() {
      return this.$store.state.info.menus.careers.fields.children
    },
    social: function() {
      return this.$store.state.info.menus.social.fields.children
    },
    legal: function() {
      return this.$store.state.info.menus.legal.fields.children
    },
    copyright: function() {
      return this.$store.state.info.copyright
    },
    highContrast: function() {
      return this.$store.state.highContrast
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

footer {
  .brand {
    width: 225px;
  }
  div.copyright {
    display: flex!important;
    padding: 0;
  }
  a.copyright {
    margin: 0!important;
  }
  p.copyright {
    &:nth-of-type(1) {
      display: none;
      @media (min-width: $md) {
        display: block;
      }
    }
  }
}
</style>