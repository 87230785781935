<template>
  <footer>
    <!-- <div class="fk-menu">
      <nuxt-link to="/food">
        <img v-if="mobile" src="https://images.ctfassets.net/3dar4x4x74wk/7s9Vno3k7scVRrkx01KmmP/277b91cf9201eeab76b22b4cae669367/ME_FamilyKitchen_Banner_EatsSweets-mobile.jpeg" alt="Family Kitchen Menu Link Image">
        <img v-else src="https://images.ctfassets.net/3dar4x4x74wk/7DbmDv40HtWXK7VjQAf0gt/41cc7706618e1913ece411143b554895/ME_FamilyKitchen_Banner_EatsSweets.jpg" alt="Family Kitchen Menu Link Image"/>
      </nuxt-link>
    </div> -->
    <div class="wrapper flex flex-wrap mx-auto px-3 md:px-8 xl:px-0 justify-between">

      <!-- <div class="md:mr-auto w-full md:w-auto">
        <div class="alert-form sm:mx-0">
          <label class="block sm:text-left mb-3 sm:mb-4">Free 30 minutes of play when you sign up for email or text alerts!</label>
          <div class="flex mt-2">
            <input type="text" name="email" class="mr-2 sm:mr-4" placeholder="Email Address" />
            <button class="pulse">Submit</button>
          </div>
        </div>
        <div class="alert-form sm:mx-0">
          <div class="flex mt-2">
            <input type="text" name="phone" class="mr-2 sm:mr-4" placeholder="Mobile Number" />
            <button class="pulse">Submit</button>
          </div>
          <i>By entering your information and clicking “submit”, you are agreeing to receive marketing communications from Main Event.</i>
        </div>
      </div> -->

      <div class="w-full flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between">

        <div class="w-full md:w-2/5">
          <div class="links text-center md:text-left flex flex-wrap">
            <div class="w-1/3 sm:pr-10 mb-2" v-for="link in links" :key="link.index">
              <nuxt-link v-if="link.fields.internalLink" :to="link.fields.url" v-html="link.fields.title" />
              <a v-else :href="link.fields.url" v-html="link.fields.title" />
            </div>
          </div>
        </div>

        <div v-if="isDallas" class="dal-awards w-full md:w-1/4 flex items-center justify-center md:justify-between mb-4 md:mb-0">
          <img src="https://images.ctfassets.net/3dar4x4x74wk/7yNODTDFG9hNdbCaeEtpxf/f31b372471e604831e2536032043e18b/ME_Bestof_BowlingAlley_Lockups-04.svg" alt="Best of Dallas 2022: Best of Bowling">
          <img src="https://images.ctfassets.net/3dar4x4x74wk/2PFbrOWdEnEAUydUEHzFwx/3d70e0d26a48148bb180235da77b1ff8/ME_Bestin_FamilyFunCenter_Lockups-02.svg" alt="Best in DFW 2022: Family Fun Center">
        </div>

        <div class="w-full md:w-1/4 flex flex-wrap flex-col-reverse sm:flex-col items-stretch">
          <div class="social text-center sm:text-right sm:mb-4 sm:w-auto w-full">
            <div v-for="s in social" :key="s.index">
              <a :href="s.fields.url" :title="s.fields.title" target="_blank">
                <img :src="s.fields.image.fields.file.url" :alt="s.fields.image.fields.description" />
              </a>
            </div>
          </div>

          <div class="mx-auto sm:self-end text-center mt-auto">
            <nuxt-link to="/"><img class="brand" alt="Main Event Logo" :src="`${highContrast ? '//images.ctfassets.net/3dar4x4x74wk/ris4qQHhncvgU9MyoeoWk/ebd259bac41495ae17c6e11c2c02a83f/logo.svg' : '//images.ctfassets.net/3dar4x4x74wk/cZZNUCdSENJnx4PZ6FLZs/7d534bbd01fc55c6392bc1618a192fae/logo.svg'}`"/></nuxt-link>
            <span class="copyright">{{copyright}}</span>
          </div>
        </div>

      </div>

      <div class="legal-links w-full pt-6 flex flex-wrap md:-mx-2 justify-center md:justify-start leading-4 lg:leading-none">
        <div v-for="l in legal" :key="l.index" class="mx-2">
          <nuxt-link v-if="l.fields.internalLink" :to="l.fields.url" class="text-xs font-normal" v-html="l.fields.title" />
          <a v-else :href="l.fields.url" class="text-xs font-normal" v-html="l.fields.title" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isDallas: false,
    }
  },
  mounted() {
    this.setDallas()
  },
  computed: {
    links: function() {
      return this.$store.state.info.menus.footer.fields.children
    },
    social: function() {
      return this.$store.state.info.menus.social.fields.children
    },
    legal: function() {
      return this.$store.state.info.menus.legal.fields.children
    },
    copyright: function() {
      return this.$store.state.info.copyright
    },
    highContrast: function() {
      return this.$store.state.highContrast
    },
    mobile() {
      if (this.$mq === 'sm') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    setDallas() {
      let locs = [
        'fort-worth-north',
        'fort-worth',
        'waco',
        'grapevine',
        'grand-prairie',
        'lewisville',
        'plano',
        'frisco',
      ]

      for (var loc in locs) {
        if (loc = this.$route.params.id) {
          this.isDallas = true
        } else {
          this.isDallas = false
        }
      }
    }
  },
  watch: {
    $route() {
      this.setDallas()
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

footer {
  padding-top: 0!important;
  .fk-menu {
    width: 100%;

    a {
      width: 100%;
        img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .wrapper {
    padding-top: 25px;

    @media (min-width: $sm) {
      padding-top: 50px;
      
    }

    .dal-awards {

      img {
        width: 27%;
        height: auto;
        transform: translateY(11px);

        @media (min-width: $md) {
          width: 39%;
          transform: translateY(8px);
        }

        &:last-of-type {
          width: 42%;
          transform: unset;

          @media (min-width: $md) {
            width: 60%;
          }
        }
      }
    }
  }
}

</style>