import { OktaAuth } from '@okta/okta-auth-js'


export default (context, inject) => {
  const url = process.env.NODE_ENV == 'development' ? 'http://localhost:3000' : typeof window == 'undefined' ? process.env.URL : window.location.origin

  const oktaCreds = {
    issuer: process.env.OKTA_ISSUER,
    clientId: process.env.OKTA_CLIENT_ID,
    redirectUri: `${url}/account`,
    postLogoutRedirectUri: `${url}/login`,
    pkce: true
  }
  
  const oktaClient = new OktaAuth(oktaCreds)
  
  const okta = {
    getClient() {
      return oktaClient
    },
    async checkSession() {
      return await oktaClient.session.exists()
      .then(async exists => {
        if(exists) {
          return await oktaClient.token.getWithoutPrompt({
            responseType: ['code', 'id_token', 'code id_token', 'code token', 'id_token token', 'code id_token token'],
            scopes: ['openid', 'profile', 'email', 'address', 'phone', 'apigw', 'api:booking', 'api:order', 'api:play']
          })
          .then(data => {
            const { idToken, accessToken } = data.tokens
            oktaClient.tokenManager.add('idToken', idToken)
            oktaClient.tokenManager.add('accessToken', accessToken)
            return true
          }).catch(err => {
            console.error(err)
            return false
          })
        } else {
          return false
        }
      })
    },
    async checkLogin() {
      if(oktaClient.tokenManager) {
        return await oktaClient.tokenManager.get('idToken')
        .then(idToken => {
          context.store.commit('setLogInState', idToken ? true : false)
          return idToken ? true : false
        })
      } else {
        return false
      }
    },
    async getLogin() {
      if(oktaClient.token.isLoginRedirect()) {
        return await oktaClient.token.parseFromUrl()
        .then(async (data) => {
          const { idToken, accessToken } = data.tokens
          oktaClient.tokenManager.add('idToken', idToken)
          oktaClient.tokenManager.add('accessToken', accessToken)
          // return await oktaClient.token.getUserInfo(accessToken, idToken)
          return await oktaClient.tokenManager.get('accessToken')
        })
      } else {
        // let idToken = await oktaClient.tokenManager.get('idToken')
        // let accessToken = await oktaClient.tokenManager.get('accessToken')
        return await oktaClient.tokenManager.get('accessToken') //await oktaClient.token.getUserInfo(accessToken, idToken)
      }
    },
    async getAccessToken() {
      return await oktaClient.tokenManager.get('accessToken')
      .then(accessToken => {
        if(accessToken) {
          return accessToken.value
        } else {
          return null
        }
      })
    },
    async logIn(username, password) {
      return await oktaClient.signIn({username, password})
      .then(res => {
        if(res.status === 'SUCCESS') {
          oktaClient.token.getWithRedirect({
            sessionToken: res.sessionToken,
            responseType: ['code', 'id_token', 'code id_token', 'code token', 'id_token token', 'code id_token token'],
            scopes: ['openid', 'profile', 'email', 'address', 'phone', 'apigw', 'api:booking', 'api:order', 'api:play']
          })
          context.store.commit('setLogInState', true)
          return true
        }
      }).catch(err => {
        return false
      })
    },
    logOut() {
      oktaClient.signOut()
      context.store.commit('setLogInState', false)
      context.store.commit('unsetAccount')
    },
    async forgotPassword(username) {
      return await oktaClient.forgotPassword({
        username: username,
        factorType: 'EMAIL'
      }).then(transaction => {
        if(transaction.status == 'RECOVERY_CHALLENGE') {
          return true
        } else {
          throw `We cannot handle the ${transaction.status} status`
          return false
        }
      }).catch(err => {
        console.error(err)
        return false
      })
    },
    async resetPassword(token, password) {
      return await oktaClient.verifyRecoveryToken({
        recoveryToken: token
      }).then(async (transaction) => {
        if(transaction.status == 'SUCCESS') {
          oktaClient.session.setCookieAndRedirect(transaction.sessionToken)
          return await transaction.resetPassword({
            newPassword: password
          }).then(transaction => {
            if(transaction.status == 'SUCCESS') {
              return true
            } else {
              return false
            }
          }).catch(err => {
            console.error(err)
            return false
          })
        } else {
          throw `We cannot handle the ${transaction.status} status`
          return false
        }
      }).catch(err => {
        console.error(err)
        return false
      })
    },
    async changePassword(oldPassword, newPassword) {
      // return await oktaClient.signInWithCredentials(
      //   {
      //     username: process.env.OKTA_USERNAME,
      //     password: process.env.OKTA_PASSWORD
      //   }
      // ).then(async transaction => {
      //   return true
      // })
      return false
    },
    async getEmail() {
      return await oktaClient.tokenManager.get('idToken')
      .then(idToken => {
        return idToken ? idToken.claims.email : null
      })
    }
  }

  inject('okta', okta)
  context.$okta = okta
}