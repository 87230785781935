<template>
  <footer>
    <div class="team-building-footer mx-auto px-3 md:px-8 xl:px-0 flex flex-col sm:flex-row items-center jusitfy-center sm:justify-between">
      <img src="https://images.ctfassets.net/3dar4x4x74wk/5wESf86q5Gwc5jLKjrx7cK/babdc31b592eec8098ffb73503b109e0/ME_Teambuilding_96Bulls.svg" alt="without orville, wilbur wright would have just been some guy. ">
      <div class="spacer"/>
      <img src="https://images.ctfassets.net/3dar4x4x74wk/2M04CyfMBeIyJ8AiclOVkQ/4d750e4602514a7ca9845bbaf74b3b24/Logo_team-building.png" alt="Team Building Logo">
      <div class="spacer"/>
      <img src="https://images.ctfassets.net/3dar4x4x74wk/30SaoWgmLNJxedsvif4JbN/5030748b154c090403bca44bac1b2a0c/Lockup_better-team.png" alt="Build a better team">
    </div>
    <div class="spacer mx-auto px-3 md:px-8 xl:px-0 "/>
    <div class="wrapper flex flex-wrap mx-auto px-3 md:px-8 xl:px-0 justify-between">

      <div>
        <div class="links sm:text-left flex flex-wrap">
          <div class="w-1/3 sm:pr-10 mb-2" v-for="link in links" :key="link.index">
            <nuxt-link v-if="link.fields.internalLink" :to="link.fields.url" v-html="link.fields.title" />
            <a v-else :href="link.fields.url" v-html="link.fields.title" />
          </div>
        </div>
      </div>

      <div class="w-full md:w-auto flex flex-wrap flex-col-reverse sm:flex-col items-stretch">
        <div class="social text-center sm:text-right sm:mb-4 sm:w-auto w-full">
          <div v-for="s in social" :key="s.index">
            <a :href="s.fields.url" :title="s.fields.title" target="_blank">
              <img :src="s.fields.image.fields.file.url" :alt="s.fields.image.fields.description" />
            </a>
          </div>
        </div>

        <div class="mx-auto sm:self-end text-center mt-auto">
          <nuxt-link to="/"><img class="brand" alt="Main Event Logo" :src="`${highContrast ? '//images.ctfassets.net/3dar4x4x74wk/ris4qQHhncvgU9MyoeoWk/ebd259bac41495ae17c6e11c2c02a83f/logo.svg' : '//images.ctfassets.net/3dar4x4x74wk/cZZNUCdSENJnx4PZ6FLZs/7d534bbd01fc55c6392bc1618a192fae/logo.svg'}`"/></nuxt-link>
          <span class="copyright">{{copyright}}</span>
        </div>
      </div>

      <div class="legal-links w-full pt-6 flex flex-wrap md:-mx-2 justify-center md:justify-start leading-4 lg:leading-none">
        <div v-for="l in legal" :key="l.index" class="mx-2">
          <nuxt-link v-if="l.fields.internalLink" :to="l.fields.url" class="text-xs font-normal" v-html="l.fields.title" />
          <a v-else :href="l.fields.url" class="text-xs font-normal" v-html="l.fields.title" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    links: function() {
      return this.$store.state.info.menus.footer.fields.children
    },
    social: function() {
      return this.$store.state.info.menus.social.fields.children
    },
    legal: function() {
      return this.$store.state.info.menus.legal.fields.children
    },
    copyright: function() {
      return this.$store.state.info.copyright
    },
    highContrast: function() {
      return this.$store.state.highContrast
    }
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

footer {

  p, a, span {
    color: white;
  }
  padding-top: 0!important;
  background-image: url('https://images.ctfassets.net/3dar4x4x74wk/4WWR8Z8swPb9WSk082pIIH/fb46c96f4285ef0fc189644efebd22e6/BG_pink-green-gradient.png')!important;
  background-size: cover;
  background-position: center;

  @media (min-width: $sm) { 
    background-position: left center;
  }
  &::before {
    content: unset;
    height: 0px;
    display: none;
  }
  
  .team-building-footer {
    width: 1250px;
    max-width: 100vw;
    padding-top: 50px;
    padding-bottom: 25px;

    @media (min-width: $sm) {
      padding-bottom: 50px;
    }

    img {
      width: 80%;
      max-width: 215px;
      height: auto;
      @media (min-width: $sm) {
        width: 30%;
        height: auto;
        max-width: 300px;
      }
    }

    .spacer {
      height: 3px;
      width: 100%;
      max-width: 330px;
      margin: 25px 0;
      @media (min-width: $sm) {
        height: 150px;
        width: 3px;
        background: white;
        margin: 0;
      }
    }
  }
  
  .spacer {
    width: 1250px;
    max-width: 100vw;
    height: 4px;
    background-color: white;
  }

  .wrapper {
    padding-top: 25px;

    @media (min-width: $sm) {
      padding-top: 50px;
      
    }

    .social {
      img {
        -webkit-filter: invert(100%); /* Safari/Chrome */
        filter: invert(100%);
      }
    }
  }
}

</style>